import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import twemoji from 'twemoji';
import { Waypoint } from 'react-waypoint';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';

import Container from '../containers/Container';
import Modal from '../panels/CustomModal';
import { ButtonStyle } from '../actions/Button';

class PageHeader extends Component {
  state = {
    fixedHeaderShown: false,
    isModalOpen: false,
    sendingForm: false,
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ sendingForm: true });

    const formData = new FormData(event.target);
    const xmlhttp = new XMLHttpRequest();
    const context = this;
    const encodedData = [];

    formData.entries().forEach(([key, value]) => {
      encodedData.push(`${key}=${value}`);
    });

    xmlhttp.onreadystatechange = () => {
      if (xmlhttp.readyState === 4) {
        const response = JSON.parse(xmlhttp.responseText);
        if (xmlhttp.status === 200 && response.result === 'success') {
          context.setState({ isModalOpen: false, sendingForm: false }, () => {
            toast('💌  Obrigado pela recomendação!');
          });
        } else {
          context.setState({ sendingForm: false }, () => {
            toast.error('Ouch! Algo deu errado por aqui. 😔');
          });
        }
      }
    };

    xmlhttp.open('POST', event.target.action);
    xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xmlhttp.send(encodedData.join('&'));
  }

  onShowFixedHeader = () => {
    this.setState({ fixedHeaderShown: true });
  }

  onHideFixedHeader = () => {
    this.setState({ fixedHeaderShown: false });
  }

  onToggleModal = () => {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  }

  render() {
    let emoji;
    let backAction;
    let formActionLabel = 'Enviar';
    let disabled = false;

    if (this.props.emoji) {
      emoji = <Emoji dangerouslySetInnerHTML={{ __html: twemoji.parse(this.props.emoji) }} />;
    }

    if (this.props.hasBackButton) {
      backAction = (
        <BackButton to="/">
          <svg viewBox="0 0 24 24">
            <path fill="#FFFFFF" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
          </svg>
        </BackButton>
      );
    }

    if (this.state.sendingForm) {
      formActionLabel = 'Enviando...';
      disabled = true;
    }

    return (
      <Fragment>
        <PageHero>
          <Container>
            <HeaderActions>
              {backAction}

              <Button
                type="button"
                onClick={this.onToggleModal}
                title="Indique novos conteúdos para nosso repositório!"
              >
                Sugerir Conteúdo
              </Button>
            </HeaderActions>

            {emoji}

            <Title>{this.props.title}</Title>
            <Description>{this.props.description}</Description>
          </Container>
        </PageHero>

        <Waypoint
          onEnter={this.onHideFixedHeader}
          onLeave={this.onShowFixedHeader}
        />

        <FixedHeader visible={this.state.fixedHeaderShown}>
          <FixedHeaderContainer>
            {backAction}
            {emoji}

            <FixedHeaderTitle>{this.props.title}</FixedHeaderTitle>
          </FixedHeaderContainer>
        </FixedHeader>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.isModalOpen}
          onRequestClose={this.onToggleModal}
        >
          <CloseButton onClick={this.onToggleModal}>
            <svg viewBox="0 0 24 24">
                <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </CloseButton>

          <ModalTitle>Novo conteúdo</ModalTitle>
          <ModalDescription>
            Indique recursos e materiais que você conheça ou tenha produzido para que possamos incluir nesta lista!
          </ModalDescription>

          <Form
            method="post"
            onSubmit={this.onSubmit}
            action="https://script.google.com/macros/s/AKfycbwguq0Je24I_OwsysyIQqa5UDgc-m7BqtPXujMER_REHotCGXI/exec"
          >
            <input type="email" name="email" placeholder="Seu email (obrigatório)" required />
            <input type="text" name="url" placeholder="Link do recurso (obrigatório)" required />
            <textarea name="description" placeholder="Descrição" />

            <input type="submit" value={formActionLabel} disabled={disabled} />
          </Form>
        </Modal>

        <ToastContainer />
      </Fragment>
    );
  }
}

const PageHero = styled.div`
  background: #4776e6;
  background: -webkit-linear-gradient(110deg, #4776e6, #8e54e9);
  background: linear-gradient(110deg, #4776e6, #8e54e9);
  padding: 30px 0 120px;
  text-align: center;

  @media (max-width : 768px) {
    padding-top: 10px;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 70px;
`;

const Button = styled.button`
  ${ButtonStyle}
  margin-left: auto;
`;

const Title = styled.h1`
  color: #FFF;
  margin-bottom: 15px;
  -webkit-font-smoothing: antialiased;
`;

const Description = styled.p`
  font-size: 22px;
  color: rgba(255, 255, 255, 0.6);

  @media (max-width : 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Emoji = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding: 20px;
  margin: 0 auto 15px;
  max-width: 100px;
  max-height: 100px;
`;

const BackButton = styled(Link)`
  display: flex;
  margin-right: 20px
  max-width: 20px;

  svg { width: 24px; }
`;

const FixedHeader = styled.div`
  position: fixed;
  background: #4776e6;
  background: -webkit-linear-gradient(110deg, #4776e6, #8e54e9);
  background: linear-gradient(110deg, #4776e6, #8e54e9);
  align-items: flex-start;
  overflow: hidden;
  height: 70px;
  width: 100%;
  top: -70px;
  z-index: 2000;
  transition: all 0.2s linear;
  opacity: 0;
  top: -70px;

  ${props => props.visible && (`
    opacity: 1;
    top: 0;
  `)}

  ${Emoji} {
    border-radius: 21px;
    margin: 0 15px 0;
    padding: 10px;
    max-width: 42px;
    max-height: 42px;  
  }
`;

const FixedHeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0px 1.0875rem;
  max-width: 960px;
  height: 100%;
`;

const FixedHeaderTitle = styled(Title)`
  font-size: 22px;
  margin: 0;

  @media (max-width : 768px) {
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  right: 20px;
  top: 20px;
  opacity: 0.3;

  svg { width: 24px; }
`;

const ModalTitle = styled.h2`
  margin-bottom: 15px;
`;

const ModalDescription = styled.p`
  color: #999;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  input:not([type=submit]), textarea {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    margin-bottom: 12px;
    padding: 10px;
    border-color: transparent;
    border-bottom-color: #EEE;
    border-width: 2px;
  }

  input:not([type=submit]):focus, textarea:focus {
    outline: none;
    border-bottom-color: #8e54e9;
  }

  input:disabled { opacity: 0.5 }
  input:-moz-ui-invalid {
    outline: none;
    box-shadow: none;
    border-bottom-color: #E8308C;
  }

  input[type=submit] {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
    background: transparent;
    color: #8e54e9;
    border: 1px solid #8e54e9;
    border-radius: 0.4rem;
    padding: 12px 22px;
    cursor: pointer;

    :hover, :focus {
      background: #8e54e9;
      color: #FFF;
    }
  }
`;

Container.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  emoji: PropTypes.string,
  hasBackButton: PropTypes.boolean,
  backgroundColor: PropTypes.string,
};

export default PageHeader;
