import React from 'react';
import PropTypes from 'prop-types';
import twemoji from 'twemoji';
import styled from 'styled-components';

import Container from '../containers/Container';
import { ButtonStyle } from '../actions/Button';
import amigofonoLogo from '../../assets/images/amigofono.svg';

const Footer = ({ pathname }) => (
  <ShareContainer>
    <Emoji dangerouslySetInnerHTML={{ __html: twemoji.parse('❤️') }} />

    <Title>Conhecimento se compartilha!</Title>
    <Description>
      Se você conhece alguém que pode se beneficiar com esses conteúdos,
      não deixe de compartilhar, ok?
    </Description>

    <div>
      <FacebookLink
        href={`https://www.facebook.com/sharer/sharer.php?u=https://recursos.amigofono.com.br${pathname}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
        </svg>

        Compartilhar
      </FacebookLink>

      <WhatsappLink
        href={`whatsapp://send?text=Olha só essa página com recursos fonoaudiológicos 😍! https://recursos.amigofono.com.br${pathname}`}
        data-action="share/whatsapp/share"
      >
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
        </svg>

        Compartilhar
      </WhatsappLink>

      <AmigofonoLink href="https://amigofono.com.br/?utm_source=resourcers-list&utm_content=footer-link">
        <img src={amigofonoLogo} alt="Amigofono" />
      </AmigofonoLink>
    </div>
  </ShareContainer>
);

const ShareContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 80px auto;
  max-width: 600px;
`;

const Emoji = styled.div`
  background-color: #EEE;
  border-radius: 40px;
  padding: 20px;
  width: 80px;
  height: 80px;
`;

const Title = styled.h2`
  margin: 16px 0 10px;
`;

const Description = styled.p`
  color: #999;
`;

const SocialLink = styled.a`
  ${ButtonStyle}
  border: none;
  color: #FFF;
  margin: 0 5px 10px;

  svg {
    display: inline-block;
    vertical-align: text-bottom;
    margin-bottom: 0;
    margin-right: 8px;
    width: 16px;
  }
`;

const FacebookLink = styled(SocialLink)`
  background-color: #3b5998;
`;

const WhatsappLink = styled(SocialLink)`
  background-color: #65d467;

  @media (min-width: 768px) {
    display: none !important;
  }
`;

const AmigofonoLink = styled.a`
  display: block;
  margin-top: 120px;
  margin-bottom: -60px;
`;

Footer.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Footer;
