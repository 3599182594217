import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled from 'styled-components';

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <Modal
    className={modalClassName}
    portalClassName={className}
    {...props}
  />
);

const StyledModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: "touch";
    border-radius: 0.4rem;
    outline: none;
    position: relative;
    border-color: transparent;
    box-shadow: 2px 2px 32px rgba(0, 0, 0, 0.3);
    padding: 30px 40px 40px;
    margin: auto;
    max-width: 680px;
    top: 40px;
    left: 0;
    right: 0;
  }

  .Overlay {
    background-color: rgba(71, 118, 230, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000
  }

  @media (max-width : 768px) {
    .Modal {
      top: 0;
      padding: 30px 20px 30px 20px;
      border-radius: 0;  
    }
}
`;

ReactModalAdapter.propTypes = {
  className: PropTypes.string.isRequired,
  modalClassName: PropTypes.string.isRequired,
};

export default StyledModal;
