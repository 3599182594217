import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './index.css';

const PageContainer = ({ children }) => (
  <Fragment>
    {children}
  </Fragment>
);

PageContainer.defaultProps = { children: undefined };
PageContainer.propTypes = {
  children: PropTypes.node,
};

export default PageContainer;
