import styled, { css } from 'styled-components';

const CardStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 35px 30px 35px 15px;
  border-radius: 0.4rem;
  background-color: #FFF;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  top:0;

  h3 { 
    color: rgba(0, 0, 0, 0.8); 
  }

  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  }

  @media (max-width : 768px) {
    padding-right: 20px;
  }
`;

const Card = styled.div(CardStyle);

const CardTitle = styled.h3`
  margin-bottom: 8px;
  transition: color 0.2s ease-in-out;

  @media (max-width : 768px) {
    line-height: 30px;
    margin-bottom: 4px;
  }
`;

const CardDescription = styled.p`
  color: #999;
  margin-bottom: 0;
`;


export default Card;
export { CardStyle, CardTitle, CardDescription };
