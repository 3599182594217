import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import PageContainer from '../components/containers/PageContainer';
import PageHeader from '../components/nav/PageHeader';
import Container from '../components/containers/Container';
import CategoryCard from '../components/CategoryCard';
import Footer from '../components/nav/Footer';

import coverImage from '../assets/images/cover.jpg';

const query = graphql`
  query IndexQuery {
    allCategoriesJson {
      edges {
        node {
          name
          slug
          emoji
          items {
            name
            url
          }
        }
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={query}
    render={data => (
      <PageContainer>
        <Helmet
          title="Recursos Fonoaudiológicos"
          meta={[
            { name: 'description', content: 'Um repositório de atividades, exercícios e conteúdos de fonoaudiologia para te auxiliar no seu dia a dia.' },
            { name: 'keywords', content: 'fonoaudiologia, exercícios, fonoaudiológicos, recursos, cursos, vídeos, blogs, sites, aplicativos' },
            { name: 'google-site-verification', content: 'R-4QPVYPBUod4ya1UUDA129Qxbr41zs_UVwq_hVVgCg' },
            { property: 'og:locale', content: 'pt_BR' },
            { property: 'og:image', content: coverImage },
            { name: 'og:image:type', content: 'image/jpeg' },
            { name: 'og:image:width', content: '1200' },
            { name: 'og:image:height', content: '628' },
            { property: 'og:type', content: 'website' },
          ]}
        />

        <PageHeader
          title="Recursos Fonoaudiológicos"
          description="Um repositório de atividades, exercícios e conteúdos de fonoaudiologia."
          hasBackButton={false}
        />

        <Container style={{ marginTop: -50 }}>
          {data.allCategoriesJson.edges.map(({ node }) => (
            <CategoryCard
              key={node.name}
              emoji={node.emoji}
              name={node.name}
              slug={node.slug}
              totalCount={node.items.length}
            />
          ))}
        </Container>

        <Footer pathname={location.pathname} />
      </PageContainer>
    )}
  />
);
