/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import twemoji from 'twemoji';
import { Link } from 'gatsby';

import { CardStyle, CardTitle, CardDescription } from './panels/Card';

const CategoryCard = ({
  name, slug, emoji, totalCount,
}) => (
  <Card to={slug}>
    <Emoji dangerouslySetInnerHTML={{ __html: twemoji.parse(emoji) }} />

    <DescriptionContainer>
      <CardTitle>{name}</CardTitle>
      <CardDescription>{totalCount} recursos disponíveis.</CardDescription>
    </DescriptionContainer>
  </Card>
);


const Card = styled(Link)`${CardStyle}
  text-decoration: none;

  :hover {
    h3 { 
      color: #4874e7; 
    }
  }
`;

const Emoji = styled.div`
  flex: 1;
  margin-right: 10px;
  text-align: center;

  img {
    max-width: 42px;
    margin-bottom: 0;
  }

  @media (max-width : 768px) {
    margin-right: 20px;
    margin-left: 4px;
  
    img { max-width: 32px; }
  }
`;

const DescriptionContainer = styled.div`
  flex: 9;
`;

CategoryCard.defaultProps = { totalCount: 0 };
CategoryCard.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
};

export default CategoryCard;
